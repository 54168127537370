export const menuRoles = {
    itensMenu: {
        conteudo: {
            roles: [
                'CONTEUDOS_CATALOGO_DELETAR',
                'CONTEUDOS_CATALOGO_SALVAR',
                'CONTEUDOS_CATALOGO_VISUALIZAR',
                'CONTEUDOS_ENTIDADE_VISUALIZAR',
                'CONTEUDOS_ENTIDADE_SALVAR',
                'CONTEUDOS_ENTIDADE_DELETAR'
            ],
            catalogo: {
                visualizar: ['CONTEUDOS_CATALOGO_VISUALIZAR'],
                salvar: ['CONTEUDOS_CATALOGO_SALVAR'],
                deletar: ['CONTEUDOS_CATALOGO_DELETAR']
            },
            entidade: {
                visualizar: ['CONTEUDOS_ENTIDADE_VISUALIZAR'],
                salvar: ['CONTEUDOS_ENTIDADE_SALVAR'],
                deletar: ['CONTEUDOS_ENTIDADE_DELETAR']
            }
        },
        integracoes: {
            roles: [
                'CONTEUDOS_CATALOGO_VISUALIZAR',
                'CONTEUDOS_CATALOGO_DELETAR',
                'CONTEUDOS_CATALOGO_SALVAR',
                'INTEGRACOES_REGISTRO-MARCARIO_VISUALIZAR',
                'INTEGRACOES_REGISTRO-MARCARIO_SALVAR',
                'INTEGRACOES_REGISTRO-MARCARIO_DELETAR',
                'INTEGRACOES_REGISTRO-AUTORAL_VISUALIZAR',
                'INTEGRACOES_REGISTRO-AUTORAL_SALVAR',
                'INTEGRACOES_REGISTRO-AUTORAL_DELETAR',
                'INTEGRACOES_PARTICIPANTES_DELETAR',
                'INTEGRACOES_PARTICIPANTES_VISUALIZAR',
                'INTEGRACOES_PARTICIPANTES_SALVAR',
                'INTEGRACOES_VENDAS_VISUALIZAR',
                'INTEGRACOES_VENDAS_DELETAR',
                'INTEGRACOES_VENDAS_SALVAR',
                'INTEGRACOES_INFORMACOES-MANUAIS_VISUALIZAR',
                'INTEGRACOES_INFORMACOES-MANUAIS_SALVAR',
                'INTEGRACOES_INFORMACOES-MANUAIS_DELETAR',
                'INTEGRACOES_SMUSICAL_SALVAR',
                'INTEGRACOES_SMUSICAL_DELETAR',
                'INTEGRACOES_SMUSICAL_VISUALIZAR',
                'INTEGRACOES_GONEXTTX_SALVAR',
                'INTEGRACOES_GONEXTTX_VISUALIZAR',
                'INTEGRACOES_GONEXTTX_DELETAR',
                'INTEGRACOES_RECIBOS-MANUAIS_DELETAR',
                'INTEGRACOES_RECIBOS-MANUAIS_SALVAR',
                'INTEGRACOES_RECIBOS-MANUAIS_VISUALIZAR'
            ],
            gonexttx: {
                visualizar: ['INTEGRACOES_GONEXTTX_VISUALIZAR'],
                salvar: ['INTEGRACOES_GONEXTTX_SALVAR'],
                deletar: ['INTEGRACOES_GONEXTTX_DELETAR']
            },
            registromarcario: {
                visualizar: ['INTEGRACOES_REGISTRO-MARCARIO_VISUALIZAR'],
                salvar: ['INTEGRACOES_REGISTRO-MARCARIO_SALVAR'],
                deletar: ['INTEGRACOES_REGISTRO-MARCARIO_DELETAR']
            },
            registroautoral: {
                visualizar: ['INTEGRACOES_REGISTRO-AUTORAL_VISUALIZAR'],
                salvar: ['INTEGRACOES_REGISTRO-AUTORAL_SALVAR'],
                deletar: ['INTEGRACOES_REGISTRO-AUTORAL_DELETAR']
            },
            smusical: {
                visualizar: ['INTEGRACOES_SMUSICAL_VISUALIZAR'],
                salvar: ['INTEGRACOES_SMUSICAL_SALVAR'],
                deletar: ['INTEGRACOES_SMUSICAL_DELETAR']
            },
            participantes: {
                visualizar: ['INTEGRACOES_PARTICIPANTES_VISUALIZAR'],
                salvar: ['INTEGRACOES_PARTICIPANTES_SALVAR'],
                deletar: ['INTEGRACOES_PARTICIPANTES_DELETAR']
            },
            vendasinternacionais: {
                visualizar: ['INTEGRACOES_VENDAS_VISUALIZAR'],
                salvar: ['INTEGRACOES_VENDAS_SALVAR'],
                deletar: ['INTEGRACOES_VENDAS_DELETAR']
            },
            infomanuais: {
                visualizar: ['INTEGRACOES_INFORMACOES-MANUAIS_VISUALIZAR'],
                salvar: ['INTEGRACOES_INFORMACOES-MANUAIS_SALVAR'],
                deletar: ['INTEGRACOES_INFORMACOES-MANUAIS_DELETAR']
            },
            recibosmanuais: {
                visualizar: ['INTEGRACOES_RECIBOS-MANUAIS_VISUALIZAR'],
                salvar: ['INTEGRACOES_RECIBOS-MANUAIS_SALVAR'],
                deletar: ['INTEGRACOES_RECIBOS-MANUAIS_DELETAR']
            }
        },
        parametros: {
            roles: [
                'PARAMETROS_COPRODUCAO_DELETAR',
                'PARAMETROS_COPRODUCAO_VISUALIZAR',
                'PARAMETROS_COPRODUCAO_SALVAR',
                'PARAMETROS_EXCLUSIVIDADE_VISUALIZAR',
                'PARAMETROS_EXCLUSIVIDADE_DELETAR',
                'PARAMETROS_EXCLUSIVIDADE_SALVAR',
                'PARAMETROS_PAIS_VISUALIZAR',
                'PARAMETROS_PAIS_DELETAR',
                'PARAMETROS_PAIS_SALVAR',
                'PARAMETROS_STATUS-REGISTRO-MARCARIO_VISUALIZAR',
                'PARAMETROS_STATUS-REGISTRO-MARCARIO_SALVAR',
                'PARAMETROS_STATUS-REGISTRO-MARCARIO_DELETAR',
                'PARAMETROS_TITULAR_VISUALIZAR',
                'PARAMETROS_TITULAR_SALVAR',
                'PARAMETROS_TITULAR_DELETAR',
                'PARAMETROS_CLASSIFICACAO-INDICATIVA_VISUALIZAR',
                'PARAMETROS_CLASSIFICACAO-INDICATIVA_SALVAR',
                'PARAMETROS_CLASSIFICACAO-INDICATIVA_DELETAR',
                'PARAMETROS_ATUACAO_VISUALIZAR',
                'PARAMETROS_ATUACAO_SALVAR',
                'PARAMETROS_ATUACAO_DELETAR',
                'PARAMETROS_AUTORIA-ARGUMENTO_VISUALIZAR',
                'PARAMETROS_AUTORIA-ARGUMENTO_SALVAR',
                'PARAMETROS_AUTORIA-ARGUMENTO_DELETAR'
            ],
            coproducao: {
                visualizar: ['PARAMETROS_COPRODUCAO_VISUALIZAR'],
                salvar: ['PARAMETROS_COPRODUCAO_SALVAR'],
                deletar: ['PARAMETROS_COPRODUCAO_DELETAR']
            },
            exclusividade: {
                visualizar: ['PARAMETROS_EXCLUSIVIDADE_VISUALIZAR'],
                salvar: ['PARAMETROS_EXCLUSIVIDADE_SALVAR'],
                deletar: ['PARAMETROS_EXCLUSIVIDADE_DELETAR']
            },
            pais: {
                visualizar: ['PARAMETROS_PAIS_VISUALIZAR'],
                salvar: ['PARAMETROS_PAIS_SALVAR'],
                deletar: ['PARAMETROS_PAIS_DELETAR']
            },
            statusregistromarcario: {
                visualizar: ['PARAMETROS_STATUS-REGISTRO-MARCARIO_VISUALIZAR'],
                salvar: ['PARAMETROS_STATUS-REGISTRO-MARCARIO_SALVAR'],
                deletar: ['PARAMETROS_STATUS-REGISTRO-MARCARIO_DELETAR']
            },
            titular: {
                visualizar: ['PARAMETROS_TITULAR_VISUALIZAR'],
                salvar: ['PARAMETROS_TITULAR_SALVAR'],
                deletar: ['PARAMETROS_TITULAR_DELETAR']
            },
            classificacaoindicativa: {
                visualizar: ['PARAMETROS_CLASSIFICACAO-INDICATIVA_VISUALIZAR'],
                salvar: ['PARAMETROS_CLASSIFICACAO-INDICATIVA_SALVAR'],
                deletar: ['PARAMETROS_CLASSIFICACAO-INDICATIVA_DELETAR']
            },
            designacao: {
                visualizar: ['PARAMETROS_DESIGNACAO_VISUALIZAR'],
                salvar: ['PARAMETROS_DESIGNACAO_SALVAR'],
                deletar: ['PARAMETROS_DESIGNACAO_DELETAR']
            },
            formato: {
                visualizar: ['PARAMETROS_FORMATO_VISUALIZAR'],
                salvar: ['PARAMETROS_FORMATO_SALVAR'],
                deletar: ['PARAMETROS_FORMATO_DELETAR']
            },
            atuacao: {
                visualizar: ['PARAMETROS_ATUACAO_VISUALIZAR'],
                salvar: ['PARAMETROS_ATUACAO_SALVAR'],
                deletar: ['PARAMETROS_ATUACAO_DELETAR']
            },
            autoriaargumento: {
                visualizar: ['PARAMETROS_AUTORIA-ARGUMENTO_VISUALIZAR'],
                salvar: ['PARAMETROS_AUTORIA-ARGUMENTO_SALVAR'],
                deletar: ['PARAMETROS_AUTORIA-ARGUMENTO_DELETAR']
            }
        },
        gestaoDeCargas: {
            roles: [
                'GESTAO-DE-CARGAS_REGISTRO-MARCARIO_DELETAR',
                'GESTAO-DE-CARGAS_REGISTRO-MARCARIO_SALVAR',
                'GESTAO-DE-CARGAS_REGISTRO-MARCARIO_VISUALIZAR',
                'GESTAO-DE-CARGAS_REGISTRO-AUTORAL_DELETAR',
                'GESTAO-DE-CARGAS_REGISTRO-AUTORAL_SALVAR',
                'GESTAO-DE-CARGAS_REGISTRO-AUTORAL_VISUALIZAR'
            ],
            registromarcario: {
                visualizar: ['GESTAO-DE-CARGAS_REGISTRO-MARCARIO_VISUALIZAR'],
                salvar: ['GESTAO-DE-CARGAS_REGISTRO-MARCARIO_SALVAR'],
                deletar: ['GESTAO-DE-CARGAS_REGISTRO-MARCARIO_DELETAR']
            },
            registroautoral: {
                visualizar: ['GESTAO-DE-CARGAS_REGISTRO-AUTORAL_VISUALIZAR'],
                salvar: ['GESTAO-DE-CARGAS_REGISTRO-AUTORAL_SALVAR'],
                deletar: ['GESTAO-DE-CARGAS_REGISTRO-AUTORAL_DELETAR']
            }
        }
    }
};
