import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { menuRoles } from './core/constants/roles';
import { permissaoRotaGuard } from './core/guards/permissao-rota.guard';

const routes: Routes = [
    {
        path: '',
        pathMatch: 'full',
        redirectTo: 'catalogo'
    },
    {
        path: 'nao-autorizado',
        loadChildren: () =>
            import('./pages/sem-autorizacao/sem-autorizacao.module').then(
                (m) => m.SemAutorizacaoModule
            )
    },
    {
        path: 'integracoes/gmedia',
        loadChildren: () =>
            import('./pages/integracoes/gmedia/gmedia.module').then((m) => m.GmediaModule),
        canActivate: [permissaoRotaGuard],
        data: {
            roles: menuRoles.itensMenu.integracoes.gmediatx.visualizar
        }
    },
    {
        path: 'integracoes/apol',
        loadChildren: () =>
            import('./pages/integracoes/apol/apol.module').then((m) => m.ApolModule),
        canActivate: [permissaoRotaGuard],
        data: {
            roles: menuRoles.itensMenu.integracoes.apol.visualizar
        }
    },
    {
        path: 'integracoes/cbl',
        loadChildren: () => import('./pages/integracoes/cbl/cbl.module').then((m) => m.CblModule),
        canActivate: [permissaoRotaGuard],
        data: {
            roles: menuRoles.itensMenu.integracoes.cbl.visualizar
        }
    },
    {
        path: 'integracoes/participantes',
        loadChildren: () =>
            import('./pages/integracoes/participantes/participantes.module').then(
                (m) => m.ParticipantesModule
            ),
        canActivate: [permissaoRotaGuard],
        data: {
            roles: menuRoles.itensMenu.integracoes.participantes.visualizar
        }
    },
    {
        path: 'integracoes/smusical',
        loadChildren: () =>
            import('./pages/integracoes/smusical/smusical.module').then((m) => m.SmusicalModule),
        canActivate: [permissaoRotaGuard],
        data: {
            roles: menuRoles.itensMenu.integracoes.smusical.visualizar
        }
    },
    {
        path: 'integracoes/vendas-internacionais',
        loadChildren: () =>
            import('./pages/integracoes/vendas-internacionais/vendas-internacionais.module').then(
                (m) => m.VendasInternacionaisModule
            ),
        canActivate: [permissaoRotaGuard],
        data: {
            roles: menuRoles.itensMenu.integracoes.vendasinternacionais.visualizar
        }
    },
    {
        path: 'parametros/coproducao',
        loadChildren: () =>
            import('./pages/parametros/coproducao/coproducao.module').then(
                (m) => m.CoproducaoModule
            ),
        canActivate: [permissaoRotaGuard],
        data: {
            roles: menuRoles.itensMenu.parametros.coproducao.visualizar
        }
    },
    {
        path: 'parametros/exclusividade',
        loadChildren: () =>
            import('./pages/parametros/exclusividade/exclusividade.module').then(
                (m) => m.ExclusividadeModule
            ),
        canActivate: [permissaoRotaGuard],
        data: {
            roles: menuRoles.itensMenu.parametros.exclusividade.visualizar
        }
    },
    {
        path: 'catalogo',
        loadChildren: () =>
            import('./pages/catalogo/catalogo.module').then((m) => m.CatalogoModule),
        canActivate: [permissaoRotaGuard],
        data: {
            roles: menuRoles.itensMenu.conteudo.catalogo.visualizar
        }
    },
    {
        path: 'integracoes/info-manuais',
        loadChildren: () =>
            import('./pages/info-manuais/info-manuais.module').then((m) => m.InfoManuaisModule),
        canActivate: [permissaoRotaGuard],
        data: {
            roles: menuRoles.itensMenu.integracoes.infomanuais.visualizar
        }
    },
    {
        path: 'parametros/pais',
        loadChildren: () => import('./pages/parametros/pais/pais.module').then((m) => m.PaisModule),
        canActivate: [permissaoRotaGuard],
        data: {
            roles: menuRoles.itensMenu.parametros.pais.visualizar
        }
    },
    {
        path: 'parametros/status-apol',
        loadChildren: () =>
            import('./pages/parametros/status-apol/status-apol.module').then(
                (m) => m.StatusApolModule
            ),
        canActivate: [permissaoRotaGuard],
        data: {
            roles: menuRoles.itensMenu.parametros.statusapol.visualizar
        }
    },
    {
        path: 'parametros/titular',
        loadChildren: () =>
            import('./pages/parametros/titular/titular.module').then((m) => m.TitularModule),
        canActivate: [permissaoRotaGuard],
        data: {
            roles: menuRoles.itensMenu.parametros.titular.visualizar
        }
    },
    {
        path: 'parametros/classificacao-indicativa',
        loadChildren: () =>
            import(
                './pages/parametros/classificacao-indicativa/classificacao-indicativa.module'
            ).then((m) => m.ClassificacaoIndicativaModule),
        canActivate: [permissaoRotaGuard],
        data: {
            roles: menuRoles.itensMenu.parametros.classificacaoindicativa.visualizar
        }
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {}
