import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, InjectionToken, NgModule } from '@angular/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
    AUTH_INTERCEPTOR_CONFIG,
    AplicacaoService,
    AuthInterceptor,
    CORE_CONFIG_TOKEN,
    CookieService,
    CopyrightModule,
    CoreService,
    ErrorInterceptor,
    LOCAL_STORAGE_CONFIG_TOKEN,
    MenuLateralModule,
    NavBarModule,
    PERMISSOES_SERVICE_TOKEN,
    PermissoesService
} from '@sbt-suite/components';
import { lastValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

export const WINDOW = new InjectionToken<Window>('WINDOW', {
    providedIn: 'root',
    factory: () => window
});

function appInitializer(
    coreService: CoreService,
    aplicacaoService: AplicacaoService,
    cookieService: CookieService
) {
    return async () => {
        if (environment.USE_BACKEND) {
            if (!coreService.isAppToken()) {
                aplicacaoService.setLoadingPage(true);
                const token = cookieService.get(environment.ACCESS_TOKEN);
                try {
                    const response = await lastValueFrom(coreService.getAppToken(token));
                    coreService.setTokens(response.access_token, response.refresh_token);
                    coreService.setPermissoes(response.access_token);
                } catch (error) {
                    console.error(error);
                    window.location.href = environment.URL_LOGIN;
                } finally {
                    aplicacaoService.setLoadingPage(false);
                }
            }
        }
    };
}

function getProviders() {
    const providers = [
        {
            provide: APP_INITIALIZER,
            useFactory: appInitializer,
            deps: [CoreService, AplicacaoService, CookieService],
            multi: true
        },
        // Provedores opcionais que dependem do backend, assim pode se dar um bypass
        ...(environment.USE_BACKEND
            ? [
                  {
                      provide: HTTP_INTERCEPTORS,
                      useClass: AuthInterceptor,
                      multi: true
                  },
                  {
                      provide: AUTH_INTERCEPTOR_CONFIG,
                      useValue: {
                          urlLogin: environment.URL_LOGIN
                      }
                  }
              ]
            : []),
        {
            provide: CORE_CONFIG_TOKEN,
            useValue: {
                URL_BASE: environment.APP_URL_LOGIN,
                app: environment.APP,
                URL_LOGIN: environment.URL_LOGIN,
                accessToken: environment.ACCESS_TOKEN
            }
        },
        {
            provide: LOCAL_STORAGE_CONFIG_TOKEN,
            useValue: environment
        },
        {
            provide: PERMISSOES_SERVICE_TOKEN,
            useClass: PermissoesService
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorInterceptor,
            multi: true
        },
        {
            provide: WINDOW,
            useValue: window
        }
    ];

    return providers;
}

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        HttpClientModule,
        MatSnackBarModule,
        NavBarModule,
        MenuLateralModule,
        CopyrightModule
    ],
    providers: getProviders(),
    bootstrap: [AppComponent]
})
export class AppModule {}
