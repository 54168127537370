import { Component, Inject } from '@angular/core';
import {
    CookieService,
    IMenu,
    LocalStorageService,
    PERMISSOES_SERVICE_TOKEN,
    PermissoesService
} from '@sbt-suite/components';
import { environment } from 'src/environments/environment';
import { menuRoles } from './core/constants/roles';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    MODE = environment.MODE;
    URL_LOGIN = environment.URL_LOGIN;
    URL_LOGOUT = environment.URL_LOGOUT;

    menuItens: IMenu[] = [
        {
            label: '',
            roles: menuRoles.itensMenu.conteudo.roles,
            itens: [
                {
                    label: 'Catálogo',
                    icone: 'perm_media',
                    link: 'catalogo',
                    roles: Object.values(menuRoles.itensMenu.conteudo.catalogo).flat()
                }
            ]
        },
        {
            label: 'Outras Fontes',
            roles: menuRoles.itensMenu.integracoes.roles,
            itens: [
                {
                    label: 'GmediaTx',
                    icone: 'movie',
                    link: '/integracoes/gmedia',
                    roles: Object.values(menuRoles.itensMenu.integracoes.gmediatx).flat()
                },
                {
                    label: 'Apol',
                    icone: 'assignment',
                    link: '/integracoes/apol',
                    roles: Object.values(menuRoles.itensMenu.integracoes.apol).flat()
                },
                {
                    label: 'Cbl',
                    icone: 'menu_book',
                    link: '/integracoes/cbl',
                    roles: Object.values(menuRoles.itensMenu.integracoes.cbl).flat()
                },
                {
                    label: 'Participantes',
                    icone: 'group',
                    link: '/integracoes/participantes',
                    roles: Object.values(menuRoles.itensMenu.integracoes.participantes).flat()
                },
                {
                    label: 'Smusical',
                    icone: 'music_note',
                    link: '/integracoes/smusical',
                    roles: Object.values(menuRoles.itensMenu.integracoes.smusical).flat()
                },
                // {
                //     label: 'DIREITOS CONEXOS',
                //     icone: 'copyright',
                //     link: '/integracoes/direitos-conexos',
                //     roles: Object.values(menuRoles.itensMenu.integracoes.direitosConexos).flat()
                // },
                {
                    label: 'Vendas',
                    icone: 'public',
                    link: '/integracoes/vendas-internacionais',
                    roles: Object.values(
                        menuRoles.itensMenu.integracoes.vendasinternacionais
                    ).flat()
                },
                {
                    label: 'Informações Manuais',
                    icone: 'article',
                    link: '/integracoes/info-manuais',
                    roles: Object.values(menuRoles.itensMenu.integracoes.infomanuais).flat()
                }
            ]
        },
        {
            label: 'Parâmetros',
            roles: menuRoles.itensMenu.parametros.roles,
            itens: [
                {
                    label: 'Exclusividade',
                    icone: 'verified_user',
                    link: '/parametros/exclusividade',
                    roles: Object.values(menuRoles.itensMenu.parametros.exclusividade).flat()
                },
                {
                    label: 'Coprodução',
                    icone: 'group_work',
                    link: '/parametros/coproducao',
                    roles: Object.values(menuRoles.itensMenu.parametros.coproducao).flat()
                },
                {
                    label: 'País',
                    icone: 'flag',
                    link: '/parametros/pais',
                    roles: Object.values(menuRoles.itensMenu.parametros.pais).flat()
                },
                {
                    label: 'Status APOL',
                    icone: 'military_tech',
                    link: '/parametros/status-apol',
                    roles: Object.values(menuRoles.itensMenu.parametros.statusapol).flat()
                },
                {
                    label: 'Titular',
                    icone: 'domain',
                    link: '/parametros/titular',
                    roles: Object.values(menuRoles.itensMenu.parametros.titular).flat()
                },
                {
                    label: 'Classificação Indicativa',
                    icone: 'inventory',
                    link: '/parametros/classificacao-indicativa',
                    roles: Object.values(
                        menuRoles.itensMenu.parametros.classificacaoindicativa
                    ).flat()
                }
            ]
        }
    ];

    constructor(
        @Inject(PERMISSOES_SERVICE_TOKEN) private _permissoesService: PermissoesService,
        private _cookie: CookieService,
        private _localStorage: LocalStorageService
    ) {
        if (!environment.USE_BACKEND) {
            _localStorage.setItem('appToken', 'teste');
            _permissoesService.registrarPermissoes(['TESTE_VISUALIZAR']);
        }
        _permissoesService.initPermissoes();
    }

    async logout(event: boolean) {
        if (event) {
            try {
                this.clearCookie();
                this._localStorage.clear();
                window.open(this.URL_LOGOUT, '_self');
            } catch (error) {
                console.error(error);
            }
        }
    }

    private clearCookie() {
        let domain = '';
        if (this.MODE == 'dev' || this.MODE == 'prod') {
            domain = 'tvsbt.com.br';
        }
        this._cookie.delete('access_token', '/', domain);
        this._cookie.delete('refreshToken', '/', domain);
    }
}
