import { Component, Inject } from '@angular/core';
import {
    CookieService,
    IMenu,
    LocalStorageService,
    PERMISSOES_SERVICE_TOKEN,
    PermissoesService
} from '@sbt-suite/components';
import { environment } from 'src/environments/environment';
import { menuRoles } from './core/constants/roles';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    MODE = environment.MODE;
    URL_LOGIN = environment.URL_LOGIN;
    URL_LOGOUT = environment.URL_LOGOUT;

    menuItens: IMenu[] = [
        {
            label: '',
            roles: menuRoles.itensMenu.conteudo.roles,
            itens: [
                {
                    label: 'Catálogo',
                    icone: 'perm_media',
                    link: 'catalogo',
                    roles: Object.values(menuRoles.itensMenu.conteudo.catalogo).flat()
                },
                {
                    label: 'Entidade',
                    icone: 'person_add',
                    link: 'entidade',
                    roles: Object.values(menuRoles.itensMenu.conteudo.entidade).flat()
                }
            ]
        },
        {
            label: 'Outras Fontes',
            roles: menuRoles.itensMenu.integracoes.roles,
            itens: [
                {
                    label: 'GoNext TX',
                    icone: 'movie',
                    link: '/integracoes/gonexttx',
                    roles: Object.values(menuRoles.itensMenu.integracoes.gonexttx).flat()
                },
                {
                    label: 'Registro Marcário',
                    icone: 'assignment',
                    link: '/integracoes/registro-marcario',
                    roles: Object.values(menuRoles.itensMenu.integracoes.registromarcario).flat()
                },
                {
                    label: 'Registro Autoral',
                    icone: 'menu_book',
                    link: '/integracoes/registro-autoral',
                    roles: Object.values(menuRoles.itensMenu.integracoes.registroautoral).flat()
                },
                {
                    label: 'Participantes',
                    icone: 'group',
                    link: '/integracoes/participantes',
                    roles: Object.values(menuRoles.itensMenu.integracoes.participantes).flat()
                },
                {
                    label: 'Smusical',
                    icone: 'music_note',
                    link: '/integracoes/smusical',
                    roles: Object.values(menuRoles.itensMenu.integracoes.smusical).flat()
                },
                {
                    label: 'Recibos Manuais',
                    icone: 'receipt',
                    link: 'integracoes/recibos-manuais',
                    roles: Object.values(menuRoles.itensMenu.integracoes.recibosmanuais).flat()
                },
                // {
                //     label: 'DIREITOS CONEXOS',
                //     icone: 'copyright',
                //     link: '/integracoes/direitos-conexos',
                //     roles: Object.values(menuRoles.itensMenu.integracoes.direitosConexos).flat()
                // },
                {
                    label: 'Vendas',
                    icone: 'public',
                    link: '/integracoes/vendas',
                    roles: Object.values(
                        menuRoles.itensMenu.integracoes.vendasinternacionais
                    ).flat()
                },
                {
                    label: 'Informações Manuais',
                    icone: 'article',
                    link: '/integracoes/info-manuais',
                    roles: Object.values(menuRoles.itensMenu.integracoes.infomanuais).flat()
                }
            ]
        },
        {
            label: 'Parâmetros',
            roles: menuRoles.itensMenu.parametros.roles,
            itens: [
                {
                    label: 'Exclusividade',
                    icone: 'verified_user',
                    link: '/parametros/exclusividade',
                    roles: Object.values(menuRoles.itensMenu.parametros.exclusividade).flat()
                },
                {
                    label: 'Coprodução',
                    icone: 'group_work',
                    link: '/parametros/coproducao',
                    roles: Object.values(menuRoles.itensMenu.parametros.coproducao).flat()
                },
                {
                    label: 'País',
                    icone: 'flag',
                    link: '/parametros/pais',
                    roles: Object.values(menuRoles.itensMenu.parametros.pais).flat()
                },
                {
                    label: 'Status Registro Marcário',
                    icone: 'military_tech',
                    link: '/parametros/status-registro-marcario',
                    roles: Object.values(
                        menuRoles.itensMenu.parametros.statusregistromarcario
                    ).flat()
                },
                {
                    label: 'Titular',
                    icone: 'domain',
                    link: '/parametros/titular',
                    roles: Object.values(menuRoles.itensMenu.parametros.titular).flat()
                },
                {
                    label: 'Classificação Indicativa',
                    icone: 'inventory',
                    link: '/parametros/classificacao-indicativa',
                    roles: Object.values(
                        menuRoles.itensMenu.parametros.classificacaoindicativa
                    ).flat()
                },
                {
                    label: 'Designação',
                    icone: 'assignment',
                    link: '/parametros/designacao',
                    roles: Object.values(
                        menuRoles.itensMenu.parametros.classificacaoindicativa
                    ).flat()
                },
                {
                    label: 'Formato',
                    icone: 'tune',
                    link: '/parametros/formato',
                    roles: Object.values(
                        menuRoles.itensMenu.parametros.classificacaoindicativa
                    ).flat()
                },
                {
                    label: 'Órgão de Processo',
                    icone: 'account_balance',
                    link: '/parametros/orgao-processo',
                    roles: Object.values(
                        menuRoles.itensMenu.parametros.classificacaoindicativa
                    ).flat()
                },
                {
                    label: 'Órgão de Registro',
                    icone: 'business',
                    link: '/parametros/orgao-registro',
                    roles: Object.values(
                        menuRoles.itensMenu.parametros.classificacaoindicativa
                    ).flat()
                },
                {
                    label: 'Atuação',
                    icone: 'engineering',
                    link: '/parametros/atuacao',
                    roles: Object.values(menuRoles.itensMenu.parametros.atuacao).flat()
                },
                {
                    label: 'Autoria de Argumento',
                    icone: 'article_person',
                    link: '/parametros/autoria-argumento',
                    roles: Object.values(menuRoles.itensMenu.parametros.autoriaargumento).flat()
                }
            ]
        },
        {
            label: 'Gestão de Cargas',
            roles: menuRoles.itensMenu.gestaoDeCargas.roles,
            itens: [
                {
                    label: 'Registro Marcário',
                    icone: 'assignment',
                    link: 'gestao-de-cargas/registro-marcario',
                    roles: Object.values(menuRoles.itensMenu.gestaoDeCargas.registromarcario).flat()
                },
                {
                    label: 'Registro Autoral',
                    icone: 'menu_book',
                    link: 'gestao-de-cargas/registro-autoral',
                    roles: Object.values(menuRoles.itensMenu.gestaoDeCargas.registroautoral).flat()
                }
            ]
        }
    ];

    constructor(
        @Inject(PERMISSOES_SERVICE_TOKEN) private _permissoesService: PermissoesService,
        private _cookie: CookieService,
        private _localStorage: LocalStorageService
    ) {
        if (!environment.USE_BACKEND) {
            _localStorage.setItem('appToken', 'teste');
            _permissoesService.registrarPermissoes(['TESTE_VISUALIZAR']);
        }
        _permissoesService.initPermissoes();
    }

    async logout(event: boolean) {
        if (event) {
            try {
                this.clearCookie();
                this._localStorage.clear();
                window.open(this.URL_LOGOUT, '_self');
            } catch (error) {
                console.error(error);
            }
        }
    }

    private clearCookie() {
        let domain = '';
        if (this.MODE == 'dev' || this.MODE == 'prod') {
            domain = 'tvsbt.com.br';
        }
        this._cookie.delete('access_token', '/', domain);
        this._cookie.delete('refreshToken', '/', domain);
    }
}
