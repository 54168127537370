export const menuRoles = {
    itensMenu: {
        conteudo: {
            roles: [
                'CONTEUDOS_CATALOGO_DELETAR',
                'CONTEUDOS_CATALOGO_SALVAR',
                'CONTEUDOS_CATALOGO_VISUALIZAR'
            ],
            catalogo: {
                visualizar: ['CONTEUDOS_CATALOGO_VISUALIZAR'],
                salvar: ['CONTEUDOS_CATALOGO_SALVAR'],
                deletar: ['CONTEUDOS_CATALOGO_DELETAR']
            }
        },
        integracoes: {
            roles: [
                'CONTEUDOS_CATALOGO_VISUALIZAR',
                'CONTEUDOS_CATALOGO_DELETAR',
                'CONTEUDOS_CATALOGO_SALVAR',
                'INTEGRACOES_APOL_VISUALIZAR',
                'INTEGRACOES_APOL_SALVAR',
                'INTEGRACOES_APOL_DELETAR',
                'INTEGRACOES_CBL_VISUALIZAR',
                'INTEGRACOES_CBL_SALVAR',
                'INTEGRACOES_CBL_DELETAR',
                'INTEGRACOES_PARTICIPANTES_DELETAR',
                'INTEGRACOES_PARTICIPANTES_VISUALIZAR',
                'INTEGRACOES_PARTICIPANTES_SALVAR',
                'INTEGRACOES_VENDAS-INTERNACIONAIS_VISUALIZAR',
                'INTEGRACOES_VENDAS-INTERNACIONAIS_DELETAR',
                'INTEGRACOES_VENDAS-INTERNACIONAIS_SALVAR',
                'INTEGRACOES_INFORMACOES-MANUAIS_VISUALIZAR',
                'INTEGRACOES_INFORMACOES-MANUAIS_SALVAR',
                'INTEGRACOES_INFORMACOES-MANUAIS_DELETAR',
                'INTEGRACOES_SMUSICAL_SALVAR',
                'INTEGRACOES_SMUSICAL_DELETAR',
                'INTEGRACOES_SMUSICAL_VISUALIZAR',
                'INTEGRACOES_GMEDIATX_SALVAR',
                'INTEGRACOES_GMEDIATX_VISUALIZAR',
                'INTEGRACOES_GMEDIATX_DELETAR'
            ],
            gmediatx: {
                visualizar: ['INTEGRACOES_GMEDIATX_VISUALIZAR'],
                salvar: ['INTEGRACOES_GMEDIATX_SALVAR'],
                deletar: ['INTEGRACOES_GMEDIATX_DELETAR']
            },
            apol: {
                visualizar: ['INTEGRACOES_APOL_VISUALIZAR'],
                salvar: ['INTEGRACOES_APOL_SALVAR'],
                deletar: ['INTEGRACOES_APOL_DELETAR']
            },
            cbl: {
                visualizar: ['INTEGRACOES_CBL_VISUALIZAR'],
                salvar: ['INTEGRACOES_CBL_SALVAR'],
                deletar: ['INTEGRACOES_CBL_DELETAR']
            },
            smusical: {
                visualizar: ['INTEGRACOES_SMUSICAL_VISUALIZAR'],
                salvar: ['INTEGRACOES_SMUSICAL_SALVAR'],
                deletar: ['INTEGRACOES_SMUSICAL_DELETAR']
            },
            participantes: {
                visualizar: ['INTEGRACOES_PARTICIPANTES_VISUALIZAR'],
                salvar: ['INTEGRACOES_PARTICIPANTES_SALVAR'],
                deletar: ['INTEGRACOES_PARTICIPANTES_DELETAR']
            },
            vendasinternacionais: {
                visualizar: ['INTEGRACOES_VENDAS-INTERNACIONAIS_VISUALIZAR'],
                salvar: ['INTEGRACOES_VENDAS-INTERNACIONAIS_SALVAR'],
                deletar: ['INTEGRACOES_VENDAS-INTERNACIONAIS_DELETAR']
            },
            infomanuais: {
                visualizar: ['INTEGRACOES_INFORMACOES-MANUAIS_VISUALIZAR'],
                salvar: ['INTEGRACOES_INFORMACOES-MANUAIS_SALVAR'],
                deletar: ['INTEGRACOES_INFORMACOES-MANUAIS_DELETAR']
            }
        },
        parametros: {
            roles: [
                'PARAMETROS_COPRODUCAO_DELETAR',
                'PARAMETROS_COPRODUCAO_VISUALIZAR',
                'PARAMETROS_COPRODUCAO_SALVAR',
                'PARAMETROS_EXCLUSIVIDADE_VISUALIZAR',
                'PARAMETROS_EXCLUSIVIDADE_DELETAR',
                'PARAMETROS_EXCLUSIVIDADE_SALVAR',
                'PARAMETROS_PAIS_VISUALIZAR',
                'PARAMETROS_PAIS_DELETAR',
                'PARAMETROS_PAIS_SALVAR',
                'PARAMETROS_STATUS-APOL_VISUALIZAR',
                'PARAMETROS_STATUS-APOL_SALVAR',
                'PARAMETROS_STATUS-APOL_DELETAR',
                'PARAMETROS_TITULAR_VISUALIZAR',
                'PARAMETROS_TITULAR_SALVAR',
                'PARAMETROS_TITULAR_DELETAR',
                'PARAMETROS_CLASSIFICACAO-INDICATIVA_VISUALIZAR',
                'PARAMETROS_CLASSIFICACAO-INDICATIVA_SALVAR',
                'PARAMETROS_CLASSIFICACAO-INDICATIVA_DELETAR'
            ],
            coproducao: {
                visualizar: ['PARAMETROS_COPRODUCAO_VISUALIZAR'],
                salvar: ['PARAMETROS_COPRODUCAO_SALVAR'],
                deletar: ['PARAMETROS_COPRODUCAO_DELETAR']
            },
            exclusividade: {
                visualizar: ['PARAMETROS_EXCLUSIVIDADE_VISUALIZAR'],
                salvar: ['PARAMETROS_EXCLUSIVIDADE_SALVAR'],
                deletar: ['PARAMETROS_EXCLUSIVIDADE_DELETAR']
            },
            pais: {
                visualizar: ['PARAMETROS_PAIS_VISUALIZAR'],
                salvar: ['PARAMETROS_PAIS_SALVAR'],
                deletar: ['PARAMETROS_PAIS_DELETAR']
            },
            statusapol: {
                visualizar: ['PARAMETROS_STATUS-APOL_VISUALIZAR'],
                salvar: ['PARAMETROS_STATUS-APOL_SALVAR'],
                deletar: ['PARAMETROS_STATUS-APOL_DELETAR']
            },
            titular: {
                visualizar: ['PARAMETROS_TITULAR_VISUALIZAR'],
                salvar: ['PARAMETROS_TITULAR_SALVAR'],
                deletar: ['PARAMETROS_TITULAR_DELETAR']
            },
            classificacaoindicativa: {
                visualizar: ['PARAMETROS_CLASSIFICACAO-INDICATIVA_VISUALIZAR'],
                salvar: ['PARAMETROS_CLASSIFICACAO-INDICATIVA_SALVAR'],
                deletar: ['PARAMETROS_CLASSIFICACAO-INDICATIVA_DELETAR']
            }
        }
    }
};
